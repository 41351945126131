import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4CBFED'
    },
    secondary: {
      main: '#2774CC'
    }
  },
  props: {
    MuiButton: {
      color: 'inherit'
    },
    MuiList: {
      dense: true
    },
    MuiTable: {
      size: 'small'
    },
    MuiCheckbox: {
      color: 'primary'
    },
    MuiRadio: {
      color: 'primary'
    },
    MuiSwitch: {
      color: 'primary'
    }
  },
  typography: {
    // useNextVariants: true
  }
})

export default theme
