import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'

const createClient = uri => {
  if (process.env.NODE_ENV !== 'production') {
    if (uri === 'mock') {
      return require('./create-mock-client').default()
    }
  }
  const link = createPersistedQueryLink({ useGETForHashedQueries: true }).concat(createHttpLink({ uri }))

  return new ApolloClient({
    cache: new InMemoryCache(),
    link
  })
}

export default createClient
